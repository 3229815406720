@media (max-width: 399px) {
    .scrollContainer {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .borderBox {
        width: 90%;
        height: 55vh;
    }

    .container1 {
        height: 10%;
    }

    .description {
        padding: 10px;
        padding-top: 0px;
        font-size: 11pt;
    }

    .containerText {
        height: 40%;
        padding-bottom: 5px;
    }

    
    .experienceImage {
        width: 80%;
        filter: drop-shadow(0px 0px 8px);
        max-height: 100%;
    }
}

@media (min-width: 400px) and (max-width: 699px) {
    .scrollContainer {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .borderBox {
        width: 85%;
        height: 45vh;
    }

    .container1 {
        height: 10%;
    }

    .description {
        padding: 10px;
        padding-top: 0px;
    }

    .containerText {
        height: 40%;
        padding-bottom: 5px;
    }

    .container2 {
        height: 50%;
        display: flex;
        justify-content: center ;
        align-items: center;
    }
    
    .experienceImage {
        width: 80%;
        max-height: 100%;
    }
}

@media (min-width: 700px) and (max-width: 949px) { 
    
    .parentContainer {
        display: flex;
        align-items: center;
        overflow: auto;
        height: 49vh;
        background-color: var(--secondaryAccent);
        z-index: 10;
        position: relative;
    }

    .scrollContainer {
        display: flex;
        width: max-content;
        gap: 1rem;
        height: 95%;
    }

    .parentContainer[infiniteScrolling="true"] {
        overflow: hidden;
        flex-wrap: nowrap;
        mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
        -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
        z-index: 10;
        position: relative;
        height: 49vh;
    }

    [infiniteScrolling="true"] .scrollContainer {
        width: fit-content;
        animation: scroll 30s linear infinite;
        flex-wrap: nowrap;
        transition: 0.5s;
    }

    .scrollContainer:hover {
        animation-play-state: paused;
    }

    @keyframes scroll {
        to {
            transform: translate(calc(-50% - 0.5rem));
        }
    }

    .borderBox {
        width: 40rem;
        transition: 0.2s ease-in-out;
    }

    .borderBox:hover {
        transform: scale(1.01);
    }

    .container1 {
        min-height: 20%;
        max-height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title {
        font-weight:bolder;
        color: black;
        font-size: 20pt;
    }

    .container2 {
        min-height: 50%;
        max-height: 50%;
        display: flex;
        justify-content: center ;
        align-items: center;
        
    }

    .experienceImage {
        width: 80%;
        z-index: 20;
        max-height: 100%;
    }

    .containerText {
        min-height: 33%;
    }

    .description {
        padding: 15px;
        font-size: 13pt;
    }
}

@media (min-width: 950px) { 
    
    @media (max-width: 1050px) {
        .parentContainer[infiniteScrolling="true"] {
            height: 51vh;
        }

        .description {
            padding: 20px;
            padding-top: 28px;
            font-size: 16pt;
        }

        .title {
            font-weight:bolder;
            color: black;
            font-size: 25pt;
        }
        .container1 {
            height: 15%;
        }
    }

    @media (min-width: 1051px) {
        .parentContainer[infiniteScrolling="true"] {
            height: 48vh;
        }

        .description {
            padding: 20px;
            padding-top: 28px;
        }

        .title {
            font-weight:bolder;
            color: black;
            font-size: larger;
        }

        .container1 {
            height: 20%;
        }
    
    }
    
    .parentContainer {
        display: flex;
        align-items: center;
        overflow: auto;
        height: 49vh;
        background-color: var(--secondaryAccent);
        z-index: 10;
        position: relative;
    }

    .scrollContainer {
        display: flex;
        width: max-content;
        gap: 1rem;
        height: 95%;
    }

    .parentContainer[infiniteScrolling="true"] {
        overflow: hidden;
        flex-wrap: nowrap;
        mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
        -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
        z-index: 10;
        position: relative;
    }

    [infiniteScrolling="true"] .scrollContainer {
        width: fit-content;
        animation: scroll 30s linear infinite;
        flex-wrap: nowrap;
        transition: 0.5s;
    }

    .scrollContainer:hover {
        animation-play-state: paused;
    }

    @keyframes scroll {
        to {
            transform: translate(calc(-50% - 0.5rem));
        }
    }

    .borderBox {
        width: 40rem;
        transition: 0.2s ease-in-out;
    }

    .borderBox:hover {
        transform: scale(1.01);
    }

    .container1 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title {
        font-weight:bolder;
        color: black;
    }

    .container2 {
        min-height: 50%;
        max-height: 50%;
        display: flex;
        justify-content: center ;
        align-items: center;
        
    }

    .experienceImage {
        width: 100%;
        z-index: 20;
        max-height: 100%;
    }

    .containerText {
        min-height: 33%;
    }

    .description {
        padding: 20px;
        padding-top: 28px;
    }
}

.borderBox {
    border: groove black;
    /*width*/
    /*height*/
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
    background: radial-gradient(circle at center, #d1e2e3 0, #90d3d6 150%);
    border-radius: 1rem;
    box-shadow: -4px -2px 3px rgba(0, 0, 0, 0.2),
                -5px -6px 3px rgba(0, 0, 0, 0.1),
                -9px -10px 3px rgba(0, 0, 0, 0);
}

.container1 {
    /*height*/
    display: flex;
    align-items: center;
}

.title {
    font-weight:bolder;
    color: black;
}

.container2 {
    height: 50%;
    display: flex;
    justify-content: center ;
    align-items: center;
}

.experienceImage {
    object-fit: cover;
    border: groove black 1px;
    filter: drop-shadow(0px 0px 8px);
}

