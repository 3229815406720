@media (min-width: 200px) and (max-width: 399px) {
    .header {
        overflow: hidden;
        position: relative;
        height: 15vh;
    }

    .leftside {
        display: flex;
        align-items: center;
        width: 50%;
        padding: 2px;
    }

    .pfp {
        height: 75px;
        min-width: 75px;
        background-image: url(./Images/Headshot.jpg);
        background-size: 100%;
        background-position: center -10px;
        background-repeat: no-repeat;
        clip-path: circle();
        transition: 0.2s;
    }

    .pfp:hover {
        transform: scale(1.05);
    }

    .PersonalInfo {
        display: flex;
        flex-direction: column;
        height: 80%;
        gap: 6px;
        padding: 2px;
        width: 100%;
        align-items: space-between;
        justify-content: space-between;
        font-size: 10pt;
        font-weight: bold;
        color:antiquewhite;
    }


    .rightside {
        display: flex;
        height: 100%;
        justify-content: space-around;
        width: 50%;
    }

    a {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
    }

    .linkedin {
        transition: 0.1s ease-in-out;
        width: 50px;
        height: 50px;
        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
    }

    .linkedin:hover{
        transform: scale(1.05);
    }
}

@media (min-width: 400px) and (max-width: 699px) {

    .header {
        height: 13vh;
    }

    .leftside {
        width: 50%;
        padding: 2px;
    }

    .pfp {
        height: 75px;
        min-width: 75px;
    }

    .pfp:hover {
        transform: scale(1.05);
    }

    .PersonalInfo {
        height: 80%;
        gap: 0px;
        padding: 2px;
        width: 100%;
        justify-content: space-between;
        font-size: 12pt;
    }


    .rightside {
        display: flex;
        height: 100%;
        justify-content: space-around;
        width: 50%;
    }

    a {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
    }

    .linkedin {
        transition: 0.1s ease-in-out;
        width: 50px;
        height: 50px;
        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
    }

    .linkedin:hover{
        transform: scale(1.05);
    }
}

@media (min-width: 700px) and (max-width: 949px) {

    .header {
        justify-content: space-between;

    }

    .leftside {
        display: flex;
        align-items: center;
        width: 50%;
    }

    .pfp {
        width: 10vh;
        height: 8vh;
        margin-left: 2vh;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px;
        background-image: url(./Images/Headshot.jpg);
        background-size: 100%;
        background-position: center -10px;
        background-repeat: no-repeat;
        clip-path: circle();
        transition: 0.2s;
    }

    .pfp:hover {
        transform: scale(1.05);
    }

    .PersonalInfo {
        display: flex;
        flex-direction: column;
        height: 6vh;
        align-items: space-between;
        justify-content: space-between;
        font-size: 16pt;
        font-weight: bold;
        color:antiquewhite;
    }

    .instagram {
        padding-right: 10vh;
    }

    .rightside {
        display: flex;
        justify-content: end;
        padding-right: 5vw;
        gap: 5vw;
        width: 50%;
        align-items: center;
    }

    .linkedin {
        max-width: 7vh;
        transition: 0.1s ease-in-out;
        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
    }

    .linkedin:hover{
        transform: scale(1.05);
    }
}


@media (min-width: 950px) {    

    .header {
        display: flex;
        justify-content: space-between;
        background-color: var(--secondary);
        align-items: center;
        overflow: hidden;
        box-shadow: inset;
        border-radius: 1rem;
        box-shadow: 0px 7px 3px rgba(0, 0, 0, 0.15);
        z-index: 1;
        position: relative;
    }

    .leftside {
        display: flex;
        align-items: center;
    }

    .pfp {
        width: 10vh;
        height: 8vh;
        margin-left: 8vh;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px;
        background-image: url(./Images/Headshot.jpg);
        background-size: 100%;
        background-position: center -10px;
        background-repeat: no-repeat;
        clip-path: circle();
        transition: 0.2s;
    }

    .pfp:hover {
        transform: scale(1.05);
    }

    .PersonalInfo {
        display: flex;
        flex-direction: column;
        height: 6vh;
        align-items: space-between;
        justify-content: space-between;
        font-size: 16pt;
        font-weight: bold;
        color:antiquewhite;
    }

    .instagram {
        padding-right: 10vh;
    }

    .rightside {
        display: flex;
        gap: 5vh;
        align-items: center;
        padding-right: 5vw;
    }

    .linkedin {
        max-width: 7vh;
        transition: 0.1s ease-in-out;
        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
    }

    .linkedin:hover{
        transform: scale(1.05);
    }
}

.header {
    display: flex;
    background-color: var(--secondary);
    align-items: center;
    overflow: hidden;
    box-shadow: inset;
    border-radius: 1rem;
    box-shadow: 0px 7px 3px rgba(0, 0, 0, 0.15);
    z-index: 1;
    position: relative;
}

.leftside {
    display: flex;
    align-items: center;
}

.pfp {
    background-image: url(./Images/Headshot.jpg);
    background-size: 100%;
    background-position: center -10px;
    background-repeat: no-repeat;
    clip-path: circle();
    transition: 0.2s;
}

.pfp:hover {
    transform: scale(1.05);
}

.PersonalInfo {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
    font-weight: bold;
    color:antiquewhite;
}

.instagram {
    padding-right: 10vh;
}

.rightside {
    display: flex;
    align-items: center;
}

.linkedin {
    transition: 0.1s ease-in-out;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
}

.linkedin:hover{
    transform: scale(1.05);
}