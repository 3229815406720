:root {
    --primary: #2C3333;
    --secondary: #395B64;
    --accent1: #b2d7d9;
    --accent2: #a2d8db;
    --secondaryAccent: #333E4C
}

* {
    font-family: 'Franklin Gothic Medium';
    margin: 0;

}

.App {
    margin: 0px;
    background-color: var(--primary);
}

.gap {
    background-color: var(--accent2);
    height: 0.5vh;
}